<template>
  <div>
    <div class="card p-3 shadow-sm">
      <form @submit.prevent="" class="w-100">
        <div class="row" v-if="user.id === selectedAuditRecord.created_by_id || isAllowed('vessel-ia.appointment.ia-report.edit-details')">
          <div class="col-12 text-right">
            <template v-if="toEdit" >
              <button class="e-btn e-btn-outlined-red ml-2" type="button" @click="cancel">Cancel
              </button>
              <button class="e-btn e-btn-blue ml-2" type="submit" @click="submit" :class="hasChanges === false ? 'e-btn-blue-disabled' : ''" :disabled="hasChanges === false">Save</button>
            </template>
            <button class="e-btn e-btn-green ml-2" type="button" v-else @click="toEdit = true">Edit Internal Audit Report</button>

          </div>
        </div>

        <div class="mb-4">
          <h6 class="h6">Internal Audit Report</h6>
          <table class="w-100">
            <tr v-if="toEdit">
              <td style="width: 25%;">Audit Reference No.</td>
              <td style="width: 50%;">
                <input type="text" class="form-control form-control-sm w-auto"
                       v-model="internalAuditReport.reference_number"
                >
              </td>
              <td style="width: 12%;"></td>
              <td></td>
            </tr>
            <tr v-else>
              <td style="width: 25%;">Audit Reference No.</td>
              <td style="width: 50%;">
                {{ internal_audit_reports[$route.params.type]?.reference_number }}
              </td>
              <td style="width: 12%;"></td>
              <td></td>
            </tr>
          </table>
        </div>

        <div class="mb-4">
          <h6 class="h6">Audit Results</h6>
          <table class="w-100">
            <tr>
              <td style="width: 25%;">With Non-Conformity/Observation?</td>
              <td v-if="toEdit === false" style="width: 50%;">{{ internal_audit_reports[$route.params.type]?.has_ncr_obs === 1 ? 'YES' : 'NO' }} </td>
              <td v-else style="width: 40%;">

                <div style="width: 160px">
                  <select-with-search
                    :key="type + '_' + internalAuditReport.has_ncr_obs"
                    v-model="internalAuditReport.has_ncr_obs"
                    :current-selected-item-id="internalAuditReport.has_ncr_obs"
                    :include-search-input="false"
                    :key-item-value="'id'"
                    :key-display-text="'name'"
                    :items="[
                    {
                      id : 0,
                      name : 'NO',
                      disabled : internal_audit_reports[$route.params.type]?.non_conformity_count !== 0 || internal_audit_reports[$route.params.type]?.observation_count !== 0
                    },
                    {
                      id : 1,
                      name : 'YES'
                    }
                   ]"
                    :element-class="'form-control w-auto form-control-sm'"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td style="width: 25%;">Non-Conformities</td>
              <td style="width: 40%;">{{ internal_audit_reports[$route.params.type]?.non_conformity_count }} (Objective Evidence: {{ internal_audit_reports[$route.params.type]?.objective_evidence_count }})</td>
            </tr>
            <tr>
              <td style="width: 25%;">Observation</td>
              <td style="width: 40%;">{{ internal_audit_reports[$route.params.type]?.observation_count }} (Objective Evidence: {{ internal_audit_reports[$route.params.type]?.obs_objective_evidence_count }})</td>
            </tr>
            <tr>
              <td style="width: 25%;">Rectified on the spot</td>
              <td style="width: 40%;">{{ internal_audit_reports[$route.params.type]?.rectified }}</td>
            </tr>
            <tr>
              <td style="width: 25%;">VPI score (2 Low 5 High)</td>
              <td style="width: 40%;">
                <font-awesome-icon
                  @click="showVpiScoreModal = true"
                  data-toggle="modal"
                  data-target="#VPI-score-modal"
                  @mouseover="calculatorColor = 'e-text-red'"
                  @mouseout="calculatorColor = ''"
                  :class="calculatorColor"
                  class="cursor-pointer"
                  :size="calculatorSize"
                  icon="calculator">
                </font-awesome-icon>

                {{ averageScore ? parseFloat(averageScore) : '' }} (PSC: {{ psc_score ? parseFloat(psc_score) : '' }}, IA: {{ selectedAuditRecord[$route.params.type]?.internal_audit_score ? parseFloat(selectedAuditRecord[$route.params.type]?.internal_audit_score) : '' }}, ICBT : {{ icbt_score ? parseFloat(icbt_score) : ''}})
              </td>
              <td style="width: 12%;"></td>
              <td></td>
            </tr>
          </table>
        </div>
        <table class="w-100 mb-3">
          <tr>
            <td style="width: 25%;"><b>Filled-up Attendance</b> <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td style="width: 50%;" v-if="toEdit">
              <template v-if="user.id === selectedAuditRecord.created_by_id || isAllowed('vessel-ia.appointment.ia-report.edit.attendance.upload')">
                <input type="file" hidden ref="audit_attendance" accept=".pdf" @change="addAttendance"/>
                <button class="e-btn e-btn-green px-3" type="button" @click="$refs.audit_attendance.click()" v-if="attendanceData.file_path === null && hasNewAttendanceFile === false">
                  Add Attendance
                </button>
                <div v-else class="dropdown dropright">
                  <font-awesome-icon icon="file-pdf" size="3x" class="force-danger-all" style="cursor: pointer"
                                     id="actionDropdown"
                                     aria-expanded="false"
                                     aria-haspopup="true"
                                     data-toggle="dropdown"
                  ></font-awesome-icon>
                  <div aria-labelledby="actionDropdown" class="dropdown-menu py-0">
                    <button class="dropdown-item text-secondary font-weight-bolder"
                            v-if="hasNewAttendanceFile === false"
                            @click="viewAttendance"
                    >
                      <font-awesome-icon icon="eye"/>
                      View
                    </button>
                    <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                            v-if="user.id === selectedAuditRecord.created_by_id || isAllowed('vessel-ia.appointment.ia-report.edit.attendance.delete')"
                            @click="deleteAttendance"
                    >
                      <font-awesome-icon icon="trash" class="force-danger-all"

                      />
                      Delete
                    </button>
                  </div>
                </div>
              </template>
            </td>
            <td v-else>
              <div class="dropdown dropright">
                <font-awesome-icon icon="file-pdf" size="3x" class="force-danger-all" style="cursor: pointer"
                                   id="actionDropdown"
                                   aria-expanded="false"
                                   aria-haspopup="true"
                                   data-toggle="dropdown"
                                   v-if="attendanceData.file_path !== null"
                ></font-awesome-icon>
                <font-awesome-icon v-else icon="file-pdf" size="3x"
                ></font-awesome-icon>
                <div aria-labelledby="actionDropdown" class="dropdown-menu py-0" v-if="isOwnVessel('vesselAuditRecord')">
                  <button class="dropdown-item text-secondary font-weight-bolder"
                          @click="viewAttendance"
                  >
                    <font-awesome-icon icon="eye"/>
                    View
                  </button>
                </div>
              </div>
            </td>
            <td style="width: 12%;"></td>
            <td></td>
          </tr>
          <tr>
            <td style="width: 25%;"><b>Checklist For Internal Audit</b> <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td style="width: 50%;" v-if="toEdit">
              <template v-if="user.id === selectedAuditRecord.created_by_id || isAllowed('vessel-ia.appointment.ia-report.edit.attendance.upload')">
                <input type="file" hidden ref="audit_checklist" accept=".pdf" @change="addCheckList"/>
                <button class="e-btn e-btn-green px-3" type="button" @click="$refs.audit_checklist.click()" v-if="checkListData.file_path === null && hasNewChecklistFile === false">
                  Add Checklist
                </button>
                <div v-else class="dropdown dropright">
                  <font-awesome-icon icon="file-pdf" size="3x" class="force-danger-all" style="cursor: pointer"
                                     id="actionDropdown"
                                     aria-expanded="false"
                                     aria-haspopup="true"
                                     data-toggle="dropdown"
                  ></font-awesome-icon>
                  <div aria-labelledby="actionDropdown" class="dropdown-menu py-0" v-if="isOwnVessel('vesselAuditRecord')">
                    <button class="dropdown-item text-secondary font-weight-bolder"
                            v-if="hasNewChecklistFile === false"
                            @click="viewChecklist"
                    >
                      <font-awesome-icon icon="eye"/>
                      View
                    </button>
                    <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                            @click="deleteChecklist"
                    >
                      <font-awesome-icon icon="trash" class="force-danger-all"

                      />
                      Delete
                    </button>
                  </div>
                </div>
              </template>
            </td>
            <td v-else>
              <div class="dropdown dropright">
                <font-awesome-icon icon="file-pdf" size="3x" class="force-danger-all" style="cursor: pointer"
                                   id="actionDropdown"
                                   aria-expanded="false"
                                   aria-haspopup="true"
                                   data-toggle="dropdown"
                                   v-if="checkListData.file_path !== null"
                ></font-awesome-icon>
                <font-awesome-icon v-else icon="file-pdf" size="3x"
                ></font-awesome-icon>
                <div aria-labelledby="actionDropdown" class="dropdown-menu py-0" v-if="isOwnVessel('vesselAuditRecord')">
                  <button class="dropdown-item text-secondary font-weight-bolder"
                          @click="viewChecklist"
                  >
                    <font-awesome-icon icon="eye"/>
                    View
                  </button>
                </div>
              </div>
            </td>
            <td style="width: 12%;"></td>
            <td></td>
          </tr>
        </table>
        <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>

        <template v-if="toEdit">
          <div class="mb-3">
            <h6 class="h6">Time Limit for Corrective Action Implementation</h6>
            <table class="w-100">
              <tr>
                <td style="width: 25%">To be done not later than <sub class="lead e-text-red font-weight-bold">*</sub></td>
                <td>
                  <input type="date" class="form-control form-control-sm w-auto"
                         v-model="internalAuditReport.ca_done_later_than"
                         :min="minimumDate"
                  >
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Date <sub class="lead e-text-red font-weight-bold">*</sub></td>
                <td>
                  <input type="date" class="form-control form-control-sm w-auto"
                         v-model="internalAuditReport.ca_time_limit_date"
                         :min="minimumDate"
                  >
                </td>
              </tr>
              <tr>
                <td style="width: 25%">
                  {{
                    $route.params.type === 'sms' ? 'Director of Ship management office' : $route.params.type === 'isps' ? 'Company Security officer' : 'Company Cyber Security officer'
                  }}
                  <sub class="lead e-text-red font-weight-bold">*</sub>
                </td>
                <td style="width: 40%">
                  <template v-if="$route.params.type === 'sms'">
                    <select-with-search
                      :key="type + '_' + internalAuditReport.ca_ship_management_director_id"
                      v-model="internalAuditReport.ca_ship_management_director_id"
                      :current-selected-item-id="internalAuditReport.ca_ship_management_director_id"
                      :include-search-input="false"
                      :key-item-value="'id'"
                      :key-display-text="'name'"
                      :items="[{
                      id : null,
                      name : '-- Select Director of Ship Management Office --'
                    }].concat(director_security.sms)"
                      :element-class="'form-control w-auto form-control-sm'"
                    />
                  </template>
                  <template v-else-if="$route.params.type === 'isps'">
                    <select-with-search
                      :key="type + '_' + internalAuditReport.ca_company_officer_id"
                      v-model="internalAuditReport.ca_company_officer_id"
                      :current-selected-item-id="internalAuditReport.ca_company_officer_id"
                      :include-search-input="false"
                      :key-item-value="'id'"
                      :key-display-text="'name'"
                      :items="[{
                      id : null,
                      name : '-- Select Company Security Officer --'
                    }].concat(director_security.isps)"
                      :element-class="'form-control w-auto form-control-sm'"
                    />
                  </template>
                  <template v-else>
                    <select-with-search
                      :key="type + '_' + internalAuditReport.ca_company_cyber_officer_id"
                      v-model="internalAuditReport.ca_company_cyber_officer_id"
                      :current-selected-item-id="internalAuditReport.ca_company_cyber_officer_id"
                      :include-search-input="false"
                      :key-item-value="'id'"
                      :key-display-text="'name'"
                      :items="[{
                      id : null,
                      name : '-- Select Company Cyber Security Officer --'
                    }].concat(director_security.cyber)"
                      :element-class="'form-control w-auto form-control-sm'"
                    />
                  </template>
                </td>
                <td style="width: 10%"></td>
                <td style="width: 50%"></td>
              </tr>
            </table>
          </div>
          <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>
          <div class="mb-3">
            <h6 class="h6">Corrective Action Implemented and reported by Master</h6>
            <table class="w-100">
              <tr>
                <td style="width: 25%">Date <sub class="lead e-text-red font-weight-bold">*</sub></td>
                <td>
                  <input type="date" class="form-control form-control-sm w-auto"
                         v-model="internalAuditReport.ca_implemented_date"
                         :min="minimumDate"
                  >
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Master <sub class="lead e-text-red font-weight-bold">*</sub></td>
                <td>
                  <input type="text" class="form-control form-control-sm w-auto"
                         v-model="internalAuditReport.ca_implemented_master"
                  />
                </td>
              </tr>
            </table>
          </div>
          <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>
          <div class="mb-3">
            <h6 class="h6">Confirmation For Corrective Action</h6>
            <table class="w-100">
              <tr>
                <td style="width: 25%">Date <sub class="lead e-text-red font-weight-bold">*</sub></td>
                <td>
                  <input type="date" class="form-control form-control-sm w-auto"
                         v-model="internalAuditReport.ca_confirmation_date"
                         :min="minimumDate"
                  >
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Audit Team Leader <sub class="lead e-text-red font-weight-bold">*</sub></td>
                <td style="width: 40%">
                  <select-with-search
                    :key="type + '_' + internalAuditReport.ca_confirmation_audit_leader"
                    v-model="internalAuditReport.ca_confirmation_audit_leader"
                    :current-selected-item-id="internalAuditReport.ca_confirmation_audit_leader"
                    :include-search-input="false"
                    :key-item-value="'id'"
                    :key-display-text="'name'"
                    :items="[{
                      id : null,
                      name : '-- Select Audit Team Leader --'
                    }].concat(audit_team_leaders)"
                    :element-class="'form-control w-auto form-control-sm'"
                  />
                </td>
                <td style="width: 65%"></td>
              </tr>
            </table>
          </div>
          <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>
          <div class="mb-3">
            <h6 class="h6">Approval of Audit Completion</h6>
            <table class="w-100">
              <tr>
                <td style="width: 25%">Date <sub class="lead e-text-red font-weight-bold">*</sub></td>
                <td>
                  <input type="date" class="form-control form-control-sm w-auto"
                         v-model="internalAuditReport.audit_approval_date"
                         :min="minimumDate"
                  >
                </td>
              </tr>
              <tr>
                <td style="width: 25%">
                  {{
                    $route.params.type === 'sms' ? 'Director of Ship management office' : $route.params.type === 'isps' ? 'Company Security officer' : 'Company Cyber Security officer'
                  }}
                  <sub class="lead e-text-red font-weight-bold">*</sub>
                </td>
                <td style="width: 40%">
                  <template v-if="$route.params.type === 'sms'">
                    <select-with-search
                      :key="type + '_' + internalAuditReport.approval_ship_management_director_id"
                      v-model="internalAuditReport.approval_ship_management_director_id"
                      :current-selected-item-id="internalAuditReport.approval_ship_management_director_id"
                      :include-search-input="false"
                      :key-item-value="'id'"
                      :key-display-text="'name'"
                      :items="[{
                      id : null,
                      name : '-- Select Director of Ship Management Office --'
                    }].concat(director_security.sms)"
                      :element-class="'form-control w-auto form-control-sm'"
                    />
                  </template>

                  <template v-else-if="$route.params.type === 'isps'">
                    <select-with-search
                      :key="type + '_' + internalAuditReport.approval_company_officer_id"
                      v-model="internalAuditReport.approval_company_officer_id"
                      :current-selected-item-id="internalAuditReport.approval_company_officer_id"
                      :include-search-input="false"
                      :key-item-value="'id'"
                      :key-display-text="'name'"
                      :items="[{
                      id : null,
                      name : '-- Select Company Security Officer --'
                    }].concat(director_security.isps)"
                      :element-class="'form-control w-auto form-control-sm'"
                    />
                  </template>

                  <template v-else>
                    <select-with-search
                      :key="type + '_' + internalAuditReport.approval_company_cyber_officer_id"
                      v-model="internalAuditReport.approval_company_cyber_officer_id"
                      :current-selected-item-id="internalAuditReport.approval_company_cyber_officer_id"
                      :include-search-input="false"
                      :key-item-value="'id'"
                      :key-display-text="'name'"
                      :items="[{
                      id : null,
                      name : '-- Select Company Cyber Security Officer --'
                    }].concat(director_security.cyber)"
                      :element-class="'form-control w-auto form-control-sm'"
                    />
                  </template>
                </td>
                <td style="width: 65%"></td>
              </tr>
            </table>
          </div>
        </template>
        <template v-else>
          <div class="mb-3">
            <h6 class="h6">Time Limit for Corrective Action Implementation</h6>
            <table class="w-100">
              <tr>
                <td style="width: 25%">To be done not later than <sub class="lead e-text-red font-weight-bold">*</sub>
                </td>
                <td>
                  {{ internalAuditReport.ca_done_later_than_human }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Date <sub class="lead e-text-red font-weight-bold">*</sub>
                </td>
                <td>
                  {{ internalAuditReport.ca_time_limit_date_human }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">
                  {{
                    $route.params.type === 'sms' ? 'Director of Ship management office' : $route.params.type === 'isps' ? 'Company Security officer' : 'Company Cyber Security officer'
                  }}
                  <sub class="lead e-text-red font-weight-bold">*</sub>
                </td>
                <td>
                  {{ CaDirectorOrSecurity }}
                </td>
              </tr>
            </table>
          </div>
          <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>
          <div class="mb-3">
            <h6 class="h6">Corrective Action Implemented and reported by Master</h6>
            <table class="w-100">
              <tr>
                <td style="width: 25%">Date <sub class="lead e-text-red font-weight-bold">*</sub></td>
                <td>
                  {{ internalAuditReport.ca_implemented_date_human }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Master <sub class="lead e-text-red font-weight-bold">*</sub></td>
                <td>
                  {{ internalAuditReport.ca_implemented_master }}
                </td>
              </tr>
            </table>
          </div>
          <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>
          <div class="mb-3">
            <h6 class="h6">Confirmation For Corrective Action</h6>
            <table class="w-100">
              <tr>
                <td style="width: 25%">Date <sub class="lead e-text-red font-weight-bold">*</sub></td>
                <td>
                  {{ internalAuditReport.ca_confirmation_date_human }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Audit Team Leader <sub class="lead e-text-red font-weight-bold">*</sub></td>
                <td>
                  {{ audit_team_leaders.filter(lead => lead.id === internalAuditReport.ca_confirmation_audit_leader)[0]?.name }}
                </td>
              </tr>
            </table>
          </div>
          <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>
          <div class="mb-3">
            <h6 class="h6">Approval of Audit Completion</h6>
            <table class="w-100">
              <tr>
                <td style="width: 25%">Date <sub class="lead e-text-red font-weight-bold">*</sub></td>
                <td>
                  {{ internalAuditReport.audit_approval_date_human }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">
                  {{
                    $route.params.type === 'sms' ? 'Director of Ship management office' : $route.params.type === 'isps' ? 'Company Security officer' : 'Company Cyber Security officer'
                  }}
                  <sub class="lead e-text-red font-weight-bold">*</sub>
                </td>
                <td>
                  {{ approvalDirectoryOrSecurity }}
                </td>
              </tr>
            </table>
          </div>
        </template>
      </form>
    </div>
    <VPIScore
      :key="'vpi_score_show_' + showVpiScoreModal"
      v-if="user.id === selectedAuditRecord.created_by_id || isAllowed('vessel-ia.appointment.ia-report.edit.vpi.edit')"
      :id="internal_audit_reports[$route.params.type]?.id"
      :internal_audit_score="selectedAuditRecord[$route.params.type]?.internal_audit_score"
      @close="showVpiScoreModal = false"
      @setPscAndIcbtScore="setPscAndIcbtScore"
    >
    </VPIScore>
  </div>
</template>

<script>

import VPIScore from "@/components/modules/ia/vessel/VPIScore.vue";
import {mapActions, mapGetters} from "vuex";
import DateMixin from "@/mixins/DateMixin";
import AuditRecordMixin from "@/mixins/AuditRecordMixin";
import AuditorMixin from "@/mixins/AuditorMixin";
import {AlertService} from "@/services/AlertService";
import {DataService} from "@/services/DataService";
import {Form} from "form-backend-validation";
import SelectWithSearch from "@/components/common/SelectWithSearch.vue";

export default {
  name: 'InternalAuditReport',
  mixins : [DateMixin, AuditRecordMixin, AuditorMixin],
  components : {
    SelectWithSearch,
    VPIScore
  },
  data() {
    return {
      calculatorSize : '2x',
      calculatorColor : '',
      toEdit : false,
      internalAuditReport : {
        id : null,
        reference_number : null,
        ca_done_later_than    :null,
        ca_time_limit_date : null,
        ca_ship_management_director_id : null,
        ca_company_officer_id : null,
        ca_company_cyber_officer_id : null,
        ca_implemented_date : null,
        ca_implemented_master : null,
        ca_confirmation_date : null,
        ca_confirmation_audit_leader : null,
        audit_approval_date : null,
        approval_ship_management_director_id : null,
        approval_company_officer_id : null,
        approval_company_cyber_officer_id : null,
      },
      attendanceData: {
        file : null,
        file_path : null,
        id_to_delete : null
      },
      checkListData : {
        file : null,
        file_path : null,
        id_to_delete : null
      },
      showAddAttendanceButton : true,
      showAddChecklistButton : true,
      showVpiScoreModal : false,
      psc_score : null,
      icbt_score : null,
      averageScore : null,
    }
  },
  methods: {
    ...mapActions([
      'getInternalAudit',
      'updateInternalAuditReport',
      'getVesselAuditRecordById',
      'getDirectorSecurity',
      'deleteInternalAuditFile'
    ]),
    setPscAndIcbtScore(data) {
      this.icbt_score = data.icbt_score;
      this.psc_score = data.psc_score;
      this.averageScore = data.averageScore;
    },
    async cancel() {
      if (this.hasChanges === false) {
        this.toEdit = false;
        await this.initialize();
        return;
      }
      if (await AlertService.cancelAlert()) {
        this.toEdit = false;
        await this.initialize();
      }

    },
    async submit() {
      const param = Object.assign({}, this.internalAuditReport);
      if (this.attendanceData.file !== null) {
        param.audit_attendance = this.attendanceData.file;
      }
      if (this.checkListData.file !== null) {
        param.audit_checklist = this.checkListData.file;
      }

      delete param.attendance_url;
      delete param.checklist_url;
      delete param.audit_approval_date_human;
      delete param.ca_confirmation_date_human;
      delete param.ca_done_later_than_human;
      delete param.ca_time_limit_date_human;
      delete param.ca_implemented_date_human;

      let paramForm = new FormData();
      for (const key in param) {
        if (param[key] === null) {
          param[key] = '';
        } else {
          param[key] = typeof param[key] === 'string' ? param[key].trim() : param[key];
        }
        paramForm.append(key, param[key] === null ? '' : param[key]);
      }

      if (this.attendanceData.id_to_delete !== null) {
        await this.deleteInternalAuditFile({
          id : param.id,
          ids : this.attendanceData.id_to_delete,
          type : 'attendance'
        });
      }

      if (this.checkListData.id_to_delete !== null) {
        await this.deleteInternalAuditFile( {
          id : param.id,
          ids : this.checkListData.id_to_delete,
          type : 'checklist'
        });
      }

      const updateResponse = await this.updateInternalAuditReport({
        id : param.id,
        form : paramForm
      });
      if (updateResponse.result === false) {
        return AlertService.errorAlert(updateResponse.message, 'UPDATING OF INTERNAL AUDIT REPORT FOR ' + this.$route.params.type.toUpperCase())
      }

      AlertService.successAlert('Updated successfully', 'UPDATING OF INTERNAL AUDIT REPORT FOR ' + this.$route.params.type.toUpperCase());
      this.toEdit = false;
      await this.initialize();

      await this.setReportTypeStatusKeys(this.selectedAuditRecord);
    },
    setInternalAudit() {
      this.internalAuditReport = {
        id : this.internal_audit_reports[this.$route.params.type].id,
        reference_number : this.internal_audit_reports[this.$route.params.type].reference_number,
        has_ncr_obs : this.internal_audit_reports[this.$route.params.type].has_ncr_obs,
        ca_done_later_than    : this.internal_audit_reports[this.$route.params.type].ca_done_later_than['date-picker'],
        ca_done_later_than_human    : this.internal_audit_reports[this.$route.params.type].ca_done_later_than.human,
        ca_time_limit_date : this.internal_audit_reports[this.$route.params.type].ca_time_limit_date['date-picker'],
        ca_time_limit_date_human : this.internal_audit_reports[this.$route.params.type].ca_time_limit_date.human,
        ca_ship_management_director_id : this.internal_audit_reports[this.$route.params.type].ca_ship_management_director_id,
        ca_company_officer_id : this.internal_audit_reports[this.$route.params.type].ca_company_officer_id,
        ca_company_cyber_officer_id : this.internal_audit_reports[this.$route.params.type].ca_company_cyber_officer_id,
        ca_implemented_date : this.internal_audit_reports[this.$route.params.type].ca_implemented_date['date-picker'],
        ca_implemented_date_human : this.internal_audit_reports[this.$route.params.type].ca_implemented_date.human,
        ca_implemented_master : this.internal_audit_reports[this.$route.params.type].ca_implemented_master,
        ca_confirmation_date : this.internal_audit_reports[this.$route.params.type].ca_confirmation_date['date-picker'],
        ca_confirmation_date_human : this.internal_audit_reports[this.$route.params.type].ca_confirmation_date.human,
        ca_confirmation_audit_leader : this.internal_audit_reports[this.$route.params.type].ca_confirmation_audit_leader,
        audit_approval_date : this.internal_audit_reports[this.$route.params.type].audit_approval_date['date-picker'],
        audit_approval_date_human : this.internal_audit_reports[this.$route.params.type].audit_approval_date.human,
        approval_ship_management_director_id : this.internal_audit_reports[this.$route.params.type].approval_ship_management_director_id,
        approval_company_officer_id : this.internal_audit_reports[this.$route.params.type].approval_company_officer_id,
        approval_company_cyber_officer_id : this.internal_audit_reports[this.$route.params.type].approval_company_cyber_officer_id,
      };

      if (this.internal_audit_reports[this.$route.params.type].audit_attendance !== null) {
        this.attendanceData.file_path = this.internal_audit_reports[this.$route.params.type].attendance_url ?? null;
      }
      if (this.internal_audit_reports[this.$route.params.type].audit_checklist !== null) {
        this.checkListData.file_path = this.internal_audit_reports[this.$route.params.type].checklist_url ?? null;
      }
    },
    async initialize() {
      this.resetAttendanceData();
      this.resetChecklistData();
      await this.getInternalAudit({
        id : this.selectedAuditRecord[this.$route.params.type].id,
        type : this.$route.params.type
      });
      this.setInternalAudit();
    },
    addAttendance() {
      this.attendanceData.file = this.$refs.audit_attendance.files[0];
      this.showAddAttendanceButton = false;
      this.$refs.audit_attendance.value = null;
    },
    addCheckList() {
      this.checkListData.file = this.$refs.audit_checklist.files[0];
      this.showAddChecklistButton = false;
      this.$refs.audit_checklist.value = null;
    },
    resetChecklistData() {
      this.checkListData = {
        file : null,
        file_path : null,
        id_to_delete : null,
      }
    },
    resetAttendanceData() {
      this.attendanceData = {
        file : null,
        file_path : null,
        id_to_delete : null,
      }
    },
    deleteChecklist() {
      this.resetChecklistData();

      if (this.internal_audit_reports[this.$route.params.type].audit_checklist !== null) {
        this.checkListData.id_to_delete = 1 // this is the id of the current checklist
      }
    },
    deleteAttendance() {
      this.resetAttendanceData();

      if (this.internal_audit_reports[this.$route.params.type].audit_attendance !== null) {
        this.attendanceData.id_to_delete = 1 // this is the id of the current attendance
      }
    },
    viewChecklist() {
      window.open(this.checkListData.file_path,'_blank');
    },
    viewAttendance() {
      window.open(this.attendanceData.file_path,'_blank');
    }
  },
  async created() {
    await this.initialize();
    await this.getDirectorSecurity();
    await this.getAuditTeamLeaders();
  },
  watch : {
    '$route.params.type'() {
      this.initialize();
    },
  },
  computed : {
    ...mapGetters([
      'internal_audit_reports',
    ]),
    hasNewAttendanceFile() {
      return this.attendanceData.file !== null;
    },
    hasNewChecklistFile() {
      return this.checkListData.file !== null;
    },
    hasChanges() {
      const originalData = {
        reference_number : this.internal_audit_reports[this.$route.params.type].reference_number,
        has_ncr_obs : this.internal_audit_reports[this.$route.params.type].has_ncr_obs,
        ca_done_later_than : this.internal_audit_reports[this.$route.params.type].ca_done_later_than['date-picker'],
        ca_time_limit_date : this.internal_audit_reports[this.$route.params.type].ca_time_limit_date['date-picker'],
        ca_ship_management_director_id : this.internal_audit_reports[this.$route.params.type].ca_ship_management_director_id,
        ca_company_officer_id : this.internal_audit_reports[this.$route.params.type].ca_company_officer_id,
        ca_company_cyber_officer_id : this.internal_audit_reports[this.$route.params.type].ca_company_cyber_officer_id,
        ca_implemented_date : this.internal_audit_reports[this.$route.params.type].ca_implemented_date['date-picker'],
        ca_implemented_master : this.internal_audit_reports[this.$route.params.type].ca_implemented_master,
        ca_confirmation_date : this.internal_audit_reports[this.$route.params.type].ca_confirmation_date['date-picker'],
        ca_confirmation_audit_leader : this.internal_audit_reports[this.$route.params.type].ca_confirmation_audit_leader,
        audit_approval_date : this.internal_audit_reports[this.$route.params.type].audit_approval_date['date-picker'],
        approval_ship_management_director_id : this.internal_audit_reports[this.$route.params.type].approval_ship_management_director_id,
        approval_company_officer_id : this.internal_audit_reports[this.$route.params.type].approval_company_officer_id,
        approval_company_cyber_officer_id : this.internal_audit_reports[this.$route.params.type].approval_company_cyber_officer_id,
      }

      const editedData = {
        reference_number : this.internalAuditReport.reference_number,
        has_ncr_obs : this.internalAuditReport.has_ncr_obs,
        ca_done_later_than : this.internalAuditReport.ca_done_later_than,
        ca_time_limit_date : this.internalAuditReport.ca_time_limit_date,
        ca_ship_management_director_id : this.internalAuditReport.ca_ship_management_director_id,
        ca_company_officer_id : this.internalAuditReport.ca_company_officer_id,
        ca_company_cyber_officer_id : this.internalAuditReport.ca_company_cyber_officer_id,
        ca_implemented_date : this.internalAuditReport.ca_implemented_date,
        ca_implemented_master : this.internalAuditReport.ca_implemented_master,
        ca_confirmation_date : this.internalAuditReport.ca_confirmation_date,
        ca_confirmation_audit_leader : this.internalAuditReport.ca_confirmation_audit_leader,
        audit_approval_date : this.internalAuditReport.audit_approval_date,
        approval_ship_management_director_id : this.internalAuditReport.approval_ship_management_director_id,
        approval_company_officer_id : this.internalAuditReport.approval_company_officer_id,
        approval_company_cyber_officer_id : this.internalAuditReport.approval_company_cyber_officer_id,
      }

      const hasAttendanceDeletedId = this.attendanceData.id_to_delete !== null;
      const hasChecklistDeletedId = this.checkListData.id_to_delete !== null;

      return hasAttendanceDeletedId || hasChecklistDeletedId || this.hasNewAttendanceFile || this.hasNewChecklistFile || DataService.checkIfChanged(originalData, editedData);
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasChanges === true) {
      if (await AlertService.cancelChanges() === true) {
        next();
      }
    } else {
      next();
    }
  }
}
</script>

<style scoped>
table td {
  padding: 2px;
}
</style>
